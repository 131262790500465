$theme: "default" !default;

$border-width: 0.8px;
$border-widths: (
  1: 1.5px,
  2: 2.5px,
  3: 3px,
  4: 4px,
  5: 5px
);
$input-disabled-bg: #E9ECEF !default;
$form-select-disabled-bg: #E9ECEF;
$enable-negative-margins: true !default;
$table-striped-bg: #FAFAFA;

$primary: #2B8EB5 !default;
$secondary: #C3CBCD !default;
$success: #26A558 !default;
$info: #169DFF !default;
$warning: #FFCC16 !default;
$danger: #D6283A !default;
$light: #F8F9FA !default;
$dark: #222222 !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark
);

@import "bootstrap/scss/bootstrap";